.documents {
  &__title {
    @include text(48px, 800, 52px);
    text-align: center;
    margin-bottom: 45px;
    letter-spacing: -1.2px;
    font-style: italic;
    span {
      color: $primary;
    }
    &--download {
      font-size: 36px;
      text-align: left;
      margin-bottom: 30px;
    }
  }
  &__desc {
    font-style: italic;
    @include text(12px, 300, 15px);
    p {
      margin-bottom: 20px;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__download {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 64px 0 80px;
  }
  &-list {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 16px;
    flex-direction: column;
    &__single {
      a {
        @include text(12px, 800, 15px, $primaryGrey);
        letter-spacing: -0.18px;
        font-style: italic;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;
        &:hover {
          color: $primary;
        }
      }
      img,
      svg {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .documents {
    &-list {
      gap: 32px;
    }
    &__title {
      font-size: 32px;
      line-height: 34px;
      margin-bottom: 10px;
    }
    &__download {
      display: flex;
      flex-direction: column;
      margin: 30px 0;
    }
  }
}
