.marquee-section {
  position: relative;
  height: 58px;
  background: transparent;
  overflow: hidden;
  z-index: 100;
  border-bottom: 1px solid rgba(#fff, 0.75);
}

.marquee {
  white-space: nowrap;

  display: flex;
  align-items: center;
  @include text(12px, 300, 15px, $white);
  font-family: $font-family-basic;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  height: 58px;
  align-items: center;
  @media (min-width: 1441px) {
    @include text(16px, 300, 18px, $white);
    letter-spacing: 3px;
  }

  strong {
    font-weight: bold;
  }
  span.dot {
    width: 13px;
    height: 13px;
    min-width: 13px;
    background: $primary;
    margin: 0px 15px;
    border-radius: 100%;
    @media (min-width: 1101px) {
      margin: 0px 30px;
    }
  }
  img {
    margin-right: 8px;
  }
}

.marquee-div {
  position: absolute;
  animation: move-right-to-left linear 120s infinite;
}

@keyframes move-left-to-right {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes move-right-to-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
