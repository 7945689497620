html {
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  &.no-scroll {
    overflow: hidden;
  }
}

body {
  box-sizing: border-box;
  font-family: $font-family-basic;
  overflow-x: hidden;
  scroll-behavior: smooth;
  @include text(16px, 400, 24px, $primaryGrey);
  &.home {
    background: $black;
  }
  &.no-scroll {
    overflow: hidden;
  }
  &.headerScrolled {
    // padding-top: 66px;
  }
}

img {
  max-width: 100%;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}
img.lazy {
  transition: 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-1 {
    order: 1;
  }
}
@media (max-width: 1200px) {
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
}
@media (min-width: 768px) {
  .hidden-sm-up {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .mobile-hidden {
    display: none !important;
  }
}
.row-center {
  align-items: center;
}

.highlighted {
  color: $primary;
}

strong {
  font-weight: bold;
}

.overflow-none {
  overflow: hidden;
}

.splide__arrow--prev,
.splide__arrow--next {
  &:before,
  &:after {
    content: "";
    width: 2px;
    height: 42px;
    background: rgba(#5c5c5b, 0.8);
    position: absolute;
  }

  &:before {
    transform: rotate(-30deg);
    top: 16px;
  }

  &:after {
    transform: rotate(30deg);
    top: -20px;
  }
  svg {
    display: none;
  }
}

.lang-switcher {
  position: relative;
  @include text(16px, 400, 24px, $white);
  .curr-language {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $white;
    cursor: pointer;
  }
  ul.language-switcher {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 5px);
    left: -10px;
    border: 1px solid #d7d6d5;
    z-index: 99999;
    min-width: 90px;
    padding: 4px 4px 4px 9px;
    min-width: 70px;
    gap: 8px;
    opacity: 0;
    transition: $transition-basic;
    border-radius: 4px;
    &.opened {
      opacity: 1;
    }
    li {
      a {
        display: flex;
        @include text(14px, 400, 21px, $white);
        align-items: center;
        gap: 6px;
        &.current-language {
          display: none;
        }
      }
    }
  }
}
