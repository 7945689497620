.homepage {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__player {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &-choice {
    z-index: 1;
    text-align: center;
    position: absolute;
    bottom: 100px;
    width: 100%;
    max-width: 800px;
    &__title {
      @include text(16px, 800, 18px, $white);
      font-style: italic;
      span {
        color: $primary;
      }
    }
    &__list {
      border-top: 1px solid rgba($white, 0.2);
      display: flex;
      justify-content: space-between;
      padding: 20px 0 0;
      margin-top: 14px;

      &-item {
        display: flex;
        align-items: center;
        a {
          transition: 0.3s ease-in-out;
          @include text(20px, 200, 22px, $white);
          font-style: italic;

          &:hover {
            text-decoration: underline;
          }
        }

        &:hover {
          .homepage-choice {
            &__list {
              &-icon {
                opacity: 1;
              }
            }
          }
        }
      }
      &-icon {
        margin-right: 10px;
        opacity: 0;
        transition: 0.3s ease-in-out;
        display: flex;
      }
    }
  }
}

@media (max-width: 991px) {
  .homepage {
    &-choice {
      max-width: 500px;
      padding: 0 20px;
      &__list {
        flex-direction: column;
        align-items: center;
        gap: 30px;
        border: none;

        &-item {
          a {
            font-weight: bold;
          }
        }

        &-icon {
          opacity: 1;
        }
      }
      &__title {
        text-align: left;
        position: relative;
        font-weight: 300;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100% - 155px);
          height: 1px;
          background-color: rgba($white, 0.2);
        }
      }
    }
    &__logo {
      top: 200px;
      svg {
        max-width: 280px;
      }
    }
  }
}

.box {
  position: relative;
  height: 231px;
  display: flex;
  align-items: flex-end;
  background-color: transparent;
  transform: scale(0.8);
  @media (min-width: 576px) and (max-width: 767px) {
    transform: scale(0.8);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    transform: scale(0.6);
  }
  @media (min-width: 992px) and (max-width: 1300px) {
    transform: scale(0.8);
  }
  @media (min-width: 1301px) {
    transform: scale(1);
  }

  .main-img {
    &--single {
      transition: $transition-basic;
      position: relative;
      z-index: 10;
    }
  }
  .shapes {
    z-index: 1;
    transition: $transition-basic;
    .shape1,
    .shape2 {
      position: absolute;
      transition: $transition-basic;
      z-index: -1;
    }
  }
  .dots {
    position: absolute;
    top: 50%;
    left: calc(50% - -30px);
    transform: translate(-50%, -50%) skewX(20deg);
    z-index: 3;
  }
  .lines {
    position: absolute;
    top: 0;
    right: -83px;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    transform: skew(0deg);
    gap: 8px;
  }

  .line {
    width: 34px;
    height: calc(100% + 55px);
    background-color: $primaryRed;
    position: relative;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
  }
  .text {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include text(44px, 800, 52px, $white);
    font-style: italic;
    margin-left: -70px;
    position: relative;
    z-index: 5;
    &--2 {
      margin-left: -20px;
    }
    &--3 {
      margin-left: -20px;
    }
    &--1cs {
      margin-left: -80px;
    }
    &--2cs {
      margin-left: 5px;
    }
    &--3cs {
      margin-left: -40px;
    }
  }

  .red-box,
  .yellow-box,
  .grey-box {
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-20deg);
    z-index: 4;
    width: 210px;
    height: 177px;
  }
  .red-box {
    background-color: $primaryRed;

    .main-img {
      img {
        position: absolute;
        z-index: 6;
        left: 50%;
        transform: translateX(-50%) skewX(20deg);
        transition: $transition-basic;
        bottom: 0px;
        width: 147px;
        height: 240px;
        object-fit: cover;
      }
      .defaultState {
        opacity: 1;
        z-index: 10;
      }
      .hoverState {
        z-index: 10;
        opacity: 0;
        width: 143px;
        height: 234px;
      }
    }
    .shapes {
      .shape1 {
        top: -100px;
        left: -70px;
        width: 270px;
        height: auto;
      }
      .shape2 {
        width: 230px;
        bottom: -40px;
        right: -127px;
        height: auto;
      }
    }
  }
  .yellow-box {
    background-color: $primary;
    .main-img {
      position: absolute;
      width: 350px;
      z-index: 6;
      margin-left: -23px;
      margin-top: -60px;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transform: skewX(20deg);
      }
    }
    .shapes {
      .shape1 {
        top: -100px;
        left: -70px;
        width: 270px;
        height: auto;
      }
      .shape2 {
        width: 230px;
        bottom: -40px;
        right: -127px;
        height: auto;
      }
    }
    .line {
      background-color: $primary;
    }
  }
  .grey-box {
    background-color: $primaryGrey;
    .line {
      background-color: $primaryGrey;
    }
    .main-img {
      width: 360px;
      margin-top: -70px;
      margin-left: -30px;
      img {
        transform: skewX(20deg);
      }
    }

    .shapes {
      .shape1 {
        top: -40px;
        left: -90px;
      }

      .shape2 {
        top: -80px;
        right: -157px;
      }
    }
  }

  &:hover {
    .red-box {
      .main-img {
        .defaultState {
          opacity: 0;
        }
        .hoverState {
          opacity: 1;
        }
      }
      .shapes {
        .shape1 {
          top: -110px !important;
          left: -120px !important;
        }
        .shape2 {
          bottom: -50px !important;
          right: -167px !important;
        }
      }
    }

    .lines {
      transition: 0.3s ease-in-out;
      .line {
        transition: 0.3s ease-in-out;
        &:first-child {
          top: -15px;
        }
        &:last-child {
          top: 15px;
        }
      }
    }

    .yellow-box {
      .main-img {
        transform: rotate(5deg) scale(1.05);
      }
      .shapes {
        .shape1 {
          top: -110px !important;
          left: -120px !important;
        }
        .shape2 {
          bottom: -50px !important;
          right: -167px !important;
        }
      }
    }
    .grey-box {
      .main-img {
        transform: rotate(5deg) scale(1.05);
      }
      .shapes {
        .shape1 {
          top: -3px;
          left: -110px;
        }
        .shape2 {
          top: -100px;
          right: -167px;
        }
      }
    }
  }
}
#homeSection {
  .splide__list {
    padding-left: 20px !important;
    @media (min-width: 992px) {
      padding-left: 60px !important;
    }
  }
  @media (max-width: 576px) {
    .splide__slide {
      display: flex;
      justify-content: center;
      margin-left: -40px;
    }
  }
}
