.footer {
  background: #161615;
  padding: 40px 0;
  &-child {
    @include text(14px, 300, 21px, $white);
    letter-spacing: -0.3px;
    strong {
      font-weight: 800;
    }
    &--column {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &__wrapper {
      display: flex;
      gap: 24px;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__menu {
      display: flex;
      gap: 40px;
      align-items: center;
      @media (min-width: 992px) {
        max-width: 50%;
      }

      &-nav {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
      }
    }

    a {
      color: $white;
      transition: 0.3s ease-in-out;
      white-space: nowrap;
      &:hover {
        color: $primary;
      }
    }
  }
  &-opening-hours {
    strong {
      white-space: nowrap;
    }
  }
  &-menu {
    &__link {
      @include text(14px, 700, 21px, $white);
      text-transform: uppercase;
      transition: 0.3s ease-in-out;
      &:hover {
        color: $primary;
      }
    }
  }
  &-locations {
    @media (max-width: 991px) {
      padding: 32px 0px;
    }
    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 32px 48px;
      @media (max-width: 991px) {
        gap: 24px;
      }
      &-item {
        display: flex;
        white-space: nowrap;
        gap: 8px;
        a {
          transition: 0.3s ease-in-out;
          @include text(20px, 200, 22px, $white);
          font-style: italic;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &-child {
      &__wrapper {
        flex-direction: column;
      }
      &__menu {
        &-nav {
          display: flex;
          gap: 16px 40px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &-child {
      &__menu {
        &-social {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &-child {
      &--column {
        justify-content: center;
        text-align: center;
        width: 100%;
      }
      &:nth-child(2) {
        order: 3;
        width: 100%;
      }
      &__menu {
        gap: 20px;
        flex-direction: column;
        &-nav {
          gap: 16px;
          flex-direction: column;
          align-items: center;
        }
        &-social {
          display: flex;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .footer {
    padding: 32px 0;
    &-child {
      text-align: center;
    }
  }
}

@media (max-width: 450px) {
  .footer {
    &-child {
      &__menu {
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
