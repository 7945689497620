.diagonal-images {
  display: flex;
  margin-top: 50px;
  &__single {
    position: relative;
    &:nth-child(2) {
      margin: 0 -30px 0;

      .diagonal-images {
        &__decor {
          top: unset;
          bottom: -55px;
          left: -60px;
        }
      }
    }

    &:last-child {
      .diagonal-images {
        &__decor {
          left: unset;
          right: -70px;
        }
      }
    }
  }
  &__decor {
    position: absolute;
    z-index: -1;
    top: -70px;
    left: 30px;
    width: 220px;
    height: 220px;
  }
}

@media (max-width: 991px) {
  .diagonal-images {
    overflow: auto;
    padding: 60px 0;
    margin: 0;
    &__single {
      img {
        min-width: 320px;
      }
    }
  }
}
