.pricing {
  &__title {
    text-align: center;
    @include text(24px, 400, 26px, $white);
    margin-bottom: 20px;
    font-style: italic;
    letter-spacing: -0.6px;
  }
  &-slider {
    &__title {
      @include text(36px, 800, 39px);
      font-style: italic;
      letter-spacing: -0.9px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 40px;

      span {
        @include text(24px, 800, 28px);
        font-style: italic;
        letter-spacing: -0.6px;
      }
    }
    &__desc {
      @include text(12px, 300, 14px);
      font-style: italic;
      max-width: 260px;
      margin-left: 40px;
    }
    &__single {
      &--standard {
        span {
          color: $primary;
          text-transform: uppercase;
        }
      }
      &--premium {
        span {
          color: $primaryRed;
          text-transform: uppercase;
        }
      }

      &--vip {
        span {
          color: $primary;
          text-transform: uppercase;
        }
      }
    }
  }
  &-button {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  &-content {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      margin: 100px 0;
    }
    &__img {
      position: absolute;
      top: 0;
      right: -200px;
    }
    &__title {
      font-style: italic;
      letter-spacing: -0.54px;
      @include text(36px, 800, 38px);
      margin-bottom: 26px;
    }
    &__subtitle {
      font-style: italic;
      letter-spacing: -0.36px;
      @include text(24px, 800, 26px);
      margin-bottom: 16px;
    }
    &__training {
      &-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
      }
      &-addition {
        display: flex;
        align-items: center;
        margin-right: 20px;
        span {
          display: block;
          margin: 0 20px;
        }
        &-title {
          max-width: 110px;
          font-style: italic;
          letter-spacing: -0.26px;
          @include text(16px, 800, 18px);
        }
      }
    }
    &__desc {
      font-style: italic;
      letter-spacing: -0.3px;
      @include text(20px, 300, 24px);

      &--xs {
        font-size: 12px;
        line-height: 14px;
      }
    }
    &__table {
      margin-bottom: 20px;
      &-wrapper {
        width: 100%;
      }
      &-title {
        font-style: italic;
        letter-spacing: -0.3px;
        @include text(16px, 800, 18px);
      }
    }
    &__row {
      display: flex;
      padding: 0 20px;

      &-price {
        border-bottom: 1px solid rgba($primaryGrey, 0.3);
        &:last-child {
          border-bottom: none;
        }
      }
    }
    &__column {
      width: 50%;

      span {
        font-style: italic;
        letter-spacing: -0.3px;
        @include text(20px, 200, 24px);
        color: #161615;
      }
      &-price {
        padding: 10px 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
  &-column {
    &__title {
      font-style: italic;
      letter-spacing: -0.54px;
      @include text(36px, 800, 48px);
      margin-bottom: 24px;
      padding: 0px 24px;
    }
    &__block {
      margin-bottom: 32px;
      &-label {
        font-style: italic;
        letter-spacing: -0.26px;
        @include text(16px, 800, 18px);
        padding: 0px 24px;
        margin-bottom: 16px;
      }
    }

    .price-block {
      padding: 0px 24px 0px;
      margin-bottom: 12px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 200%;
        left: 0;
        bottom: 0;
        background: rgba(#707070, 0.3);
      }
      &:last-child {
        &:before {
          content: none;
        }
      }
      &__label {
        @include text(20px, 200, 30px, $black);
        font-style: italic;
        letter-spacing: -0.3px;
      }
      &__price {
        padding-bottom: 12px;
      }
    }
    &:nth-child(2) {
      @media (min-width: 992px) {
        .price-block {
          &:before {
            content: none;
          }
        }
      }
    }
  }
}

.pricing-content__wrapper {
  display: none;
}

@media (max-width: 991px) {
  .pricing {
    &-button {
      &__wrapper {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
    &-content {
      &__wrapper {
        margin: 30px 0;
      }
      &__img {
        text-align: center;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 50px;
        position: inherit;
      }
    }
  }
}

@media (max-width: 767px) {
  .pricing {
    &__grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media screen and (max-device-width: 600px) {
  .pricing {
    &-content {
      &__title {
        font-size: 28px;
        line-height: 30px;
      }
      &__column {
        span {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .pricing-slider__single {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
