.section {
  &__title {
    @include text(56px, 800, 62px, $primaryGrey);
    letter-spacing: -1.4px;
    font-style: italic;
    text-align: center;

    &--standard {
      span {
        color: $primary;
      }
    }

    &--premium {
      span {
        color: $primaryRed;
      }
    }
    &--uppercase {
      text-transform: uppercase;
    }
  }
  &__desc {
    @include text(16px, 300, 20px, $primaryGrey);
    letter-spacing: -0.4px;
    font-style: italic;
    text-align: center;
    max-width: 580px;
    text-align: center;
    margin: 0 auto;
  }

  &--register-info {
    padding-top: 40px;
  }

  &--documents {
    margin-bottom: 60px;
  }

  &--birthday {
    position: relative;
    top: -160px;
    margin-bottom: -160px;

    &-img {
      position: relative;
      margin-top: 100px;
      overflow: hidden;
      img {
        position: absolute;
        width: 60%;
        bottom: -35px;
        left: -120px;
        object-position: top;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  &--sharp {
    &:before {
      content: "";
      position: absolute;
      border: 2px solid #ebebeb;
      width: 100%;
      height: 100%;
      border-left: 0;
      transform: skewX(-30deg);
      right: 10%;
      top: 125px;
      height: calc(100% - 110px);
    }
  }

  &--about {
    &-diagonal {
      margin-bottom: 70px;
    }
  }
  &--pricing {
    position: relative;
    top: -200px;
    margin-bottom: -140px;
  }
  &--contact-map {
    padding: 130px 0 150px;

    > .container {
      position: relative;
    }
  }
  &--decor {
    margin-top: 50px;
  }

  &--homepage {
    position: relative;
    top: -58px;
    margin-bottom: -58px;
    padding-bottom: 32px;
    #homeSection {
      margin-top: -90px;
      @media (min-width: 768px) and (max-width: 991px) {
        margin-top: -140px;
      }
    }
  }

  &--bg {
    position: relative;
    padding-bottom: 1px;
    &:before {
      content: "";
      background: rgba(#f7f7f7, 0.55);
      position: absolute;
      left: -15%;
      top: 20px;
      width: 100%;
      bottom: 0;
      transform: skewX(-20deg);
    }
  }
}

@media (max-width: 991px) {
  .section {
    &--contact {
      &-map {
        padding: 40px 0;
      }
    }
    &--decor {
      margin-top: 10px;
    }

    &--bg {
      &:before {
        display: none;
      }
    }
    &--sharp {
      &:before {
        display: none;
      }
    }

    &--birthday {
      top: 0;

      &-img {
        margin-top: 0;
      }
    }

    &--homepage {
      top: -10px;
      margin-bottom: -10px;
    }
  }
}

@media (max-width: 767px) {
  .section {
    &--about-diagonal {
      margin-bottom: 0;
      .container {
        padding: 0;
      }
    }
    &__title {
      text-align: left;
      font-size: 36px;
      line-height: 38px;
    }

    &__desc {
      text-align: left;
    }

    &--birthday {
      &-img {
        margin-top: 0;
        img {
          position: inherit;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .section {
    &--homepage {
      padding-top: 50px;
    }
  }
}
