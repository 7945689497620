.menu-mobile {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100;
  background: rgba($black, 0.4);
  backdrop-filter: blur(3px);
  display: none;

  &.active {
    display: block;
    overflow-y: scroll;
  }

  &__burger {
    height: 64px;
    width: 64px;
    border-radius: 100%;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    flex-direction: column;
    border: 1px solid transparent;
    position: relative;
    z-index: 100;
    transition: transform 0.3s ease, opacity 0.3s ease;
    cursor: pointer;

    span {
      height: 3px;
      background: $white;
      width: 30px;
      display: block;
      border-radius: 4px;
      transition: transform 0.3s ease, opacity 0.3s ease;
      position: absolute;
      top: 20px;
      left: 16px;
      transform-origin: left center;

      &:nth-child(2) {
        top: 30px;
      }

      &:nth-child(3) {
        top: 40px;
      }
    }

    &:hover {
      border-color: $white;
      background: transparent;
    }

    &.active {
      span {
        transform: rotate(45deg);
        transform-origin: left center;
        left: 21px;

        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__content {
    top: 180px;
    position: relative;
    text-align: center;
    width: 400px;
    margin-left: 50%;
    transform: translateX(-50%) skewX(-20deg);
    background: $primary;
    padding: 80px 0 50px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    .menu {
      display: flex;
      flex-direction: column;
      gap: 30px;

      &-item {
        a {
          @include text(24px, 800, 26px, $white);
          font-style: italic;
          letter-spacing: -0.6px;
        }
      }
    }

    > div {
      transform: skewX(20deg);
    }

    &:before,
    &:after {
      content: "";
      content: "";
      position: absolute;
      right: -50px;
      top: 20px;
      height: 100%;
      width: 40px;
      transform: skewX(0deg);
      background: $primary;
    }

    &:after {
      right: -100px;
      top: 40px;
    }
  }

  &__footer {
    text-align: center;
    background: $black;
    padding: 40px 50px 20px;
    display: flex;
    flex-direction: column;
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__social {
    display: flex;
    position: relative;
    flex-direction: row;
    top: unset;
    justify-content: center;
    margin-bottom: 30px;

    a {
      background: $primary;
      border-color: $primary;
    }
  }

  &__info {
    @include text(12px, 300, 14px, $white);

    a {
      @include text(12px, 300, 14px, $white);
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media (min-width: 768px) {
  .menu-mobile {
    &.active {
      display: none;
      overflow-y: scroll;
    }
    &__burger {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .localizationLink {
    @include text(24px, 800, 26px, $white);
    font-style: italic;
    letter-spacing: -0.6px;

    a {
      color: $white;
    }

    span {
      display: none;
    }
  }
}
