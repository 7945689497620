.custom-gallery {
  margin: 32px 0px 64px;
  @media (min-width: 992px) and (max-width: 1440px) {
    width: calc(100% + 120px);
    margin: 32px 0 64px -60px;
  }
  @media (min-width: 1441px) {
    width: calc(100% + 332px);
    margin: 32px 0 64px -166px;
  }
}
