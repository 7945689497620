// @mixin min($bp, $max: "null", $device: "screen") {
//   @if $max == "null" {
//     @media only #{$device} and (min-width: #{$bp}) {
//       @content;
//     }
//   } @else {
//     @media only #{$device} and (min-width: #{$bp}) and (max-width: #{$max}) {
//       @content;
//     }
//   }
// }

// @function bp($bp) {
//   @return map-get($breakpoints, $bp);
// }

// $breakpoints: (
//   na: 0px,
//   // For BS grid
//   xxs: 320px,
//   // Smartphone
//   xs: 576px,
//   // Smartphone
//   sm: 767px,
//   // Tablets
//   md: 991px,
//   // Tablets Landscape and small desktops
//   lg: 1200px,
//   // Desktops
//   xl: 1400px,
//   // Large Desktop
//   xxl: 1700px,
//   // XXL Desktop
// );

// @function container($container-size, $true-val: false) {
//   @return map-get($container-sizes, $container-size);
// }

// $container-sizes: (
//   xxs: 100%,
//   xs: 100%,
//   sm: calc(100% - 30px),
//   md: calc(100% - 30px),
//   lg: calc(100% - 30px),
//   xl: 1470px,
//   xxl: 1600px,
// );

// .container {
//   padding-right: 16px;
//   padding-left: 16px;
//   @media (min-width: 768px) {
//     padding-right: 16px;
//     padding-left: 16px;
//   }

//   &:not(.is-fluid) {
//     margin: 0 auto;

//     @each $bp, $container-size in $container-sizes {
//       @include min(#{bp(#{$bp})}) {
//         width: 100%;
//         max-width: container(#{$bp});
//       }
//     }
//   }
// }

// @each $bp, $container-size in $container-sizes {
//   .container-#{$bp} {
//     margin: 0 auto;
//     padding-right: 16px;
//     padding-left: 16px;
//     width: 100%;

//     $i: index($container-sizes, $bp $container-size);

//     @for $j from $i through length($container-sizes) {
//       @include min(#{bp(nth(nth($container-sizes, $j), 1))}) {
//         max-width: container(#{nth(nth($container-sizes, $j), 1)});
//       }
//     }
//   }
// }
// .container-sm {
//   max-width: 1148px;
//   width: 100%;
//   margin: 0 auto;
//   padding-right: 16px;
//   padding-left: 16px;
// }
// .container-md {
//   max-width: 1424px;
//   width: 100%;
//   margin: 0 auto;
//   padding-right: 16px;
//   padding-left: 16px;
//   @media (min-width: 768px) {
//     padding-right: 30px;
//     padding-left: 30px;
//   }
// }
// @media (max-width: 991px) {
//   .container--full-md {
//     max-width: 100% !important;
//     width: 100%;
//   }
// }

.container {
  width: 100%;
  padding: 0 15px;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;

  &--lg {
    max-width: 1600px;
  }

  &--md {
    max-width: 1180px;
  }

  &--xs {
    max-width: 1024px;
  }
}

@media (max-width: 1101px) {
  .container {
    padding-right: 36px;
    padding-left: 36px;
  }
}

@media (max-width: 350px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
    &--mobile {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
