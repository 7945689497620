$font-family-basic: "Poppins", sans-serif;

$transition-speed: 0.3s;
$transition-animation: ease-in-out;
$transition-basic: $transition-speed $transition-animation;
$transition-fast: 0.15s ease-in-out;

// COLORS

$white: #fff;
$black: #161615;
$primary: #e9ac23;
$primaryRed: #e2404e;
$primaryGrey: #5c5c5a;

$grey100: #eef0f1;
$grey200: #e2e3e6;
$grey300: #c3c8cc;
$grey400: #a9aeb2;
$grey500: #90949a;
$grey600: #707378;
$grey700: #464b52;
