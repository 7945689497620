.hero {
  position: relative;
  z-index: 0;
  height: 840px;
  padding-top: 255px;
  padding-bottom: 290px;

  @media (min-width: 1101px) {
    padding-top: 410px;
    padding-bottom: 320px;
    height: 1075px;
  }

  &--isPage {
    height: 464px;
    padding-top: 255px;
    padding-bottom: 160px;
    @media (min-width: 1101px) {
      padding-top: 410px;
      padding-bottom: 160px;
      height: 818px;
    }
  }

  #player {
    position: absolute;
    width: 100%;
    height: 464px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (min-width: 1101px) {
      height: 818px;
    }

    video,
    img {
      width: 100%;
      height: 464px;
      object-fit: cover;
      @media (min-width: 1101px) {
        height: 818px;
      }
    }
  }


  &__content {
    display: flex;
    // align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    > .container-md {
      height: 100%;
    }
  }

  &__title {
    @include text(26px, 700, 26px, $white);
    font-family: $font-family-basic;
    letter-spacing: 2px;
    text-transform: uppercase;
    word-break: break-word;

    @media (min-width: 391px) and (max-width: 576px) {
      @include text(28px, 700, 28px, $white);
    }
    @media (min-width: 577px) and (max-width: 991px) {
      @include text(48px, 700, 56px);
      max-width: 560px;
    }
    @media (min-width: 992px) {
      @include text(60px, 700, 60px);
      max-width: 612px;
    }
  }

  &__desc {
    @include text(16px, 300, 20px, $white);
    margin-top: 16px;
    max-width: 684px;
    @media (min-width: 992px) {
      @include text(20px, 300, 24px, $white);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      margin-top: -35px;
      margin-bottom: 32px;
      img {
        max-height: 150px;
      }
    }
  }

  &__action {
    display: flex;
    margin-top: 32px;

    a {
      @media (max-width: 767px) {
        height: 84px;
        font-size: 20px;
        border-radius: 0px 32px 0px 28px;
        @media (max-width: 475px) {
          width: 100%;
        }
      }
    }
  }
}

.hero--activity {
  &.color-orange {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(130deg, rgba(#017F96, 0.89) 0%, #F66A05 100%);
      mix-blend-mode: multiply;
    }
  }

  &.color-blue {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(130deg, #03aecb 0%, #464646 100%);
      mix-blend-mode: multiply;
      opacity: 0.87;
    }
  }

  &.color-magenta {
  }

  &.color-yellow {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(130deg, #4D4D4D 0%, #DFF200 100%);
      mix-blend-mode: multiply;
    }
  }

  &.color-black {
  }
}

.hero {
  &--starter {
    height: 100vh;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    #player {
      height: 100vh !important;

      video, image {
        height: 100vh !important;
      }
    }

    .hero {
      #player {
        height: 100vh !important;

        video, image {
          height: 100vh !important;
        }
      }
      &__animation {
        max-height: 50%;
      }

      &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 20px 0px 48px;
      }

      &__title {
        @include text(24px, 700, 32px, $white);
        text-transform: uppercase;
        text-align: center;
        max-width: 100%;
        margin-bottom: 24px;
        @media (min-width: 992px) {
          @include text(40px, 700, 50px, $white);
        }
      }

      &__desc {

      }

      &__action {
        flex-direction: column;
        gap: 12px;
      }

    }
  }
}
