.header-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.header {
  background: transparent;
  color: $white;
  display: flex;
  justify-content: space-between;
  height: 130px;
  z-index: 9;
  @media (min-width: 1101px) and (max-width: 1440px) {
    height: 125px;
  }
  @media (min-width: 1441px) {
    height: 175px;
  }
  > .container {
    height: 100%;
  }
  &__logo {
    max-width: 72px;
    svg {
      width: 100%;
    }
    @media (min-width: 1101px) and (max-width: 1440px) {
      max-width: 100px;
    }
    @media (min-width: 1441px) {
      max-width: none;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  &__right {
    display: flex;
    align-items: center;
    gap: 32px;
    @media (min-width: 1101px) {
      display: none;
    }
    .cart {
      position: relative;
      display: block;
      transition: $transition-basic;
      z-index: 100;
    }
  }
}
// .header {
//   position: relative;
//   background: transparent;
//   color: $white;
//   display: flex;
//   justify-content: space-between;
//   height: 66px;
//   z-index: 9;
//   &.isScrolled-up {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     transition: 0.6s;
//     background: $white;
//   }

//   &.isScrolled-down {
//     position: fixed;
//     top: -100%;
//     width: 100%;
//     left: 0;
//     transition: 0.6s;
//     background: $white;
//   }
//   @media (min-width: 1101px) {
//     height: 82px;
//   }
//   > .container {
//     height: 100%;
//   }
//   &__logo {
//     display: flex;
//     justify-content: flex-start;
//     margin-right: 24px;
//     @media (max-width: 425px) {
//       max-width: 110px;
//       svg {
//         max-width: 100%;
//       }
//       img {
//         object-fit: scale-down;
//       }
//     }
//   }
//   &__content {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 100%;
//   }
// }
