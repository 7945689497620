.not-found {
  height: 100vh;
  background: $black;
  > .container {
    height: 100%;
  }
  &__content {
    height: 100%;
    max-width: 767px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  &__logo {
    display: flex;
    margin-bottom: 24px;
    svg {
      max-width: 170px;
      height: auto;
      @media (min-width: 768px) {
        max-width: 270px;
      }
    }
  }
  &__title {
    @include text(20px, 700, 28px, $white);
    text-align: center;
    @media (min-width: 577px) and (max-width: 991px) {
      @include text(24px, 700, 32px, $white);
    }
    @media (min-width: 992px) {
      @include text(32px, 700, 48px, $white);
    }
  }
  &__desc {
    @include text(16px, 400, 24px, $grey500);
    text-align: center;
  }
  &__action {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
