.contact {
  display: flex;
  gap: 16px;
  &__img {
    width: 50%;
    padding-right: 50px;
  }
  &__wrapper {
    width: 50%;
    padding: 60px 0;
  }
  &__title {
    @include text(48px, 800, 40px, $primaryGrey);
    letter-spacing: -1.2px;
    font-style: italic;
    margin-bottom: 10px;
    span {
      color: $primary;
    }

    &--xl {
      display: flex;
      flex-direction: column;
      @include text(72px, 800, 64px, $primaryGrey);
      letter-spacing: -1.8px;
      span {
        color: $primaryRed;
      }
    }
  }
  &__desc {
    @include text(12px, 300, 16px, $primaryGrey);
    letter-spacing: -0.4px;
    font-style: italic;
  }
  &__form {
    margin-top: 50px;
    margin-top: 50px;
    position: relative;
    left: -96px;
    &-input {
      margin-bottom: 8px;
      p {
        display: flex;
        align-items: center;
      }
      label {
        width: 76px;
        text-align: right;
        @include text(16px, 800, 24px, $primaryGrey);
        font-style: italic;
        letter-spacing: -0.4px;
      }
      span {
        display: block;
        margin-left: 20px;
      }
    }

    &-terms {
      margin-left: 79px;
    }
    &-btn {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
    }
    &--extended {
      margin-left: 50px;
      left: unset;
      .contact__form {
        &-input {
          label {
            width: 110px;
          }
        }
        &-terms {
          margin-left: 113px;
        }
      }
      .wpcf7-radio {
        height: unset;
        border: none;
        margin-left: 0;
        padding-left: 0;
        span {
          margin-left: 0;
        }
        .wpcf7-list-item-label {
          margin-left: 10px;
        }
        .wpcf7-list-item {
          margin-bottom: 20px;
          label {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            text-align: left;
            cursor: pointer;
          }
        }
      }
    }
  }

  &-page {
    &__wrapper {
      display: flex;
    }
    &__hours {
      width: 40%;
      border-right: 1px solid rgba(#707070, 0.3);
      padding: 40px 40px 40px 0;

      &-title {
        font-style: italic;
        @include text(36px, 800, 40px);
        letter-spacing: -0.9px;
        text-transform: uppercase;

        span {
          color: $primary;
        }

        &--xs {
          @include text(20px, 800, 24px);
          color: $primary;
        }
      }
    }
    &__info {
      display: flex;
      align-items: center;
      &-wrapper {
        width: 30%;
        padding: 30px 0 30px 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
      &-img {
        width: 80px;
        height: 80px;
        background: $primary;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        min-height: 80px;
      }
      &-data {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-title {
          @include text(12px, 800, 14px);
          font-style: italic;
          letter-spacing: -0.18px;
        }

        a {
          @include text(24px, 800, 26px);
          font-style: italic;
          letter-spacing: -0.6px;
          color: $primaryGrey;

          &:last-child {
            font-weight: 300;
          }
        }

        p {
          a {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
    &__img {
      position: absolute;
      right: -80px;
      max-width: 445px;
      @media (min-width: 1201px) {
        position: absolute;
        right: -80px;
        max-width: 445px;
      }
    }
    &__time {
      @include text(32px, 800, 34px);
      letter-spacing: -0.48px;
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        @include text(16px, 800, 20px);
        font-style: italic;
        letter-spacing: -0.24px;
      }
      &-wrapper {
        margin: 20px 0 50px;

        &--holiday {
          margin-bottom: 0;
        }
      }
    }
  }

  &__map {
    // transform: skewX(-20deg);
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 40px;
    }
    &-decor {
      position: absolute;
      background: transparent linear-gradient(90deg, #ffffff 0%, #f5f5f5 100%)
        0% 0% no-repeat padding-box;
      width: 100%;
      height: 320px;
      z-index: -1;
      top: 10px;
      transform: skewX(-20deg);
    }
    &-wrapper {
      display: flex;
    }
    &-title {
      @include text(48px, 800, 50px);
      font-style: italic;
      letter-spacing: -1.2px;
      display: flex;
      flex-direction: column;

      span {
        color: $primary;
      }
    }

    &-desc {
      @include text(24px, 300, 26px);
      font-style: italic;
      letter-spacing: -0.6px;
    }
    &-socials {
      display: flex;
      margin-top: 10px;
      gap: 30px;
      &-wrapper {
        // margin-left: -60px;
        position: relative;
        bottom: -60px;
      }
      &-title {
        @include text(16px, 300, 18px);
        font-style: italic;
        letter-spacing: -0.4px;
        margin-bottom: 16px;

        span {
          font-weight: bold;
          color: $primary;
        }
      }
      &-single {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        border-radius: 100%;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
        svg {
          transform: scale(1.8);
        }
      }
    }

    &--mobile {
      display: none;
    }
  }
}

.wpcf7-validates-as-number {
  max-width: 75px;
  font-weight: bold;
  color: #5c5c5b;
  font-size: 20px;
}

.wpcf7-validates-as-date {
  font-weight: bold;
  color: #5c5c5b;
  font-size: 20px;
}

.wpcf7-form-control {
  border: 1px solid rgba($primaryGrey, 0.25);
  height: 50px;
  width: 200px;
  padding: 0 16px;
  font-family: $font-family-basic;
  @include text(14px, 300, 18px, $black);
  &:focus {
    border-radius: 0;
  }
  &::placeholder {
    font-style: italic;
    @include text(14px, 300, 18px, $primaryGrey);
  }
}

.contact__form-textarea {
  textarea {
    width: 350px;
    resize: none;
    padding: 16px;
    height: 95px;
    font-family: $font-family-basic;
    @include text(14px, 300, 18px, $black);
    &::placeholder {
      @include text(14px, 300, 18px, $primaryGrey);
      font-style: italic;
    }
  }
}

.wpcf7-acceptance {
  padding: 0;
  border: none;
  margin: 0;
}

.wpcf7-acceptance {
  input {
    display: none;
  }
  label {
    font-style: italic;
    letter-spacing: -0.3px;
    @include text(12px, 800, 15px);
    display: block;
    padding-left: 34px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 29px;
      height: 29px;
      border: 1px solid rgba(#707070, 0.5);
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: -8px;
      transition: all 0.12s, border-color 0.08s;
    }

    &.label-selected {
      &:before {
        content: "";
        width: 5px;
        top: 0px;
        left: 4px;
        border-radius: 0;
        opacity: 1;
        border-color: $primary;
        border-top-color: transparent;
        border-left-color: transparent;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        height: 12px;
        position: absolute;
        left: 10px;
        top: 2px;
      }
    }
  }
}

.wpcf7-not-valid-tip {
  margin-left: 0 !important;
}

.contact__form-btn {
  .wpcf7-form-control {
    border: none;
    @include text(20px, 800, 24px, $white);
    font-style: italic;
    &:before {
      width: unset;
      z-index: -1;
    }
    &:hover {
      color: $primaryGrey;
    }
  }
}

.contact__form-radio {
  /* Hide the default radio buttons */
  .wpcf7-list-item input[type="radio"] {
    display: none;
  }

  /* Custom radio button styles */
  .wpcf7-list-item label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
  }

  /* The custom radio button */
  .wpcf7-list-item label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgba(#707070, 0.5);
    background-color: #fff;
  }

  .wpcf7-list-item input[type="radio"]:checked + span::after {
    content: "";
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #5c5c5a;
  }
}

.wpcf7-form-control.wpcf7-number::-webkit-inner-spin-button,
.wpcf7-form-control.wpcf7-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wpcf7-form-control.wpcf7-number {
  -moz-appearance: textfield;
  position: relative;
  width: 100px;
  padding-right: 30px;
}

.custom-arrows {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-arrow {
  cursor: pointer;
  border: none;
  background: none;
  width: 20px;
  text-align: center;
  user-select: none;
}

.custom-arrow.increment {
  border-bottom: none;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid $primaryRed;
}

.custom-arrow.decrement {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $primaryRed;
}

@media (max-width: 1199px) {
  .contact {
    &-page {
      &__img {
        z-index: -1;
        right: -180px;
      }
    }
  }
}

@media (max-width: 991px) {
  .contact {
    flex-direction: column;
    align-items: center;

    &__form {
      &--standard {
        left: 0;

        &-btn {
          justify-content: center;
        }

        .wpcf7-form-control {
          width: 330px;
        }
        .contact__form-textarea textarea {
          width: 330px;
        }
      }
    }

    &__wrapper {
      &--standard {
        width: 100%;
      }
    }

    &__map {
      display: none;
      &-wrapper {
        flex-direction: column;
      }
      &-decor {
        display: none;
      }

      &--mobile {
        display: block;
        margin-top: 30px;
      }
      &-info {
        padding: 0;
      }
      &-socials {
        gap: 10px;
        &-wrapper {
          bottom: 0;
          margin-top: 30px;
        }
      }
    }
    &__form {
      &-textarea {
        textarea {
          width: 200px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .contact {
    &__img {
      width: 100%;
      padding-right: 0;
    }
    &__wrapper {
      width: 100%;
    }
    &__form {
      left: 0;
      margin-top: 20px;

      &--extended {
        margin-left: 0;
      }
      .wpcf7-list-item {
        margin: 0;
      }
      &-btn {
        justify-content: center;
      }
      &-input {
        p {
          flex-direction: column;
          align-items: flex-start;
        }
        label {
          text-align: left;
          margin-bottom: 8px;
          width: 100% !important;
        }
        span {
          margin-left: 0;
        }
        br {
          display: none;
        }
      }

      &-terms {
        margin-left: 0 !important;
      }

      &-textarea {
        span {
          width: 100%;
        }

        textarea {
          width: 100%;
        }
      }
    }
    &-page {
      &__hours {
        border: none;
        order: 3;
        width: 100%;
        padding: 0;
      }
      &__wrapper {
        flex-direction: column;
      }
      &__img {
        position: unset;
        order: 1;
      }
      &__info {
        &-wrapper {
          order: 0;
          width: 100%;
          padding: 0;
        }
        &-data {
          a {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
      &__time {
        &-wrapper {
          margin-bottom: 30px;
        }
      }
    }
    &__map {
      &--mobile {
        iframe {
          width: 100%;
        }
      }
    }
    &__title {
      &--xl {
        font-size: 40px;
        line-height: 42px;
      }
    }
  }
}

@media (max-width: 550px) {
  .contact {
    &__map {
      &-socials {
        justify-content: space-between;
        &-title {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .contact {
    &__wrapper {
      padding: 30px 0;
    }
    &__title {
      font-size: 32px;
      line-height: 34px;
    }

    &__form {
      &--standard {
        .wpcf7-form-control {
          width: 100%;
        }

        .contact__form-textarea {
          textarea {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .contact {
    &__map {
      &-socials {
        flex-wrap: wrap;
      }
    }
  }
}
