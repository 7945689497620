.pagination {
  margin-top: 24px;
  padding-top: 24px;
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 992px) {
    margin-top: 80px;
    justify-content: flex-end;
  }
  &-numbers {
    display: flex;
    align-items: center;
    gap: 9px;
    > div,
    > a {
      width: 40px;
      height: 40px;
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition-basic;
      @include text(24px, 900, 35px, #dbdbdb);
      font-style: italic;
      position: relative;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        width: 50px;
        max-width: 50px;
        height: 100%;
        background: transparent;
        transform: skewX(-20deg);
      }
      span {
        position: relative;
      }
      &.current {
        &:before {
          background: $primary;
        }
        color: $white;
      }
      &:not(.current) {
        &:hover {
          &:before {
            background: rgba($primary, 0.8);
          }
          color: $white;
        }
      }
    }
  }
  &-button {
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0px 5px;
    &.disabled {
      pointer-events: none;
      visibility: hidden;
      .pagination-icon {
        svg {
          fill: #dbdbdb;
        }
      }
    }
    &:hover {
      .pagination-icon {
        svg {
          fill: $primary;
        }
      }
    }
  }
  &-text {
    @include text(18px, 500, 27px, $primaryGrey);
  }
  &-icon {
    display: flex;
    align-items: center;
    svg {
      fill: #dbdbdb;
    }
  }
  &--prev {
    .pagination-icon {
      transform: rotate(-180deg);
    }
  }
}
