.modal {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999;
  background: #fff;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  transition: 0.2s ease-in;
  opacity: 0;
  visibility: hidden;
  max-height: 93vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $grey400;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }

  @media (max-width: 576px) {
    max-height: 90vh;
    max-width: 95%;
  }
  &.isOpen {
    opacity: 1;
    visibility: visible;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 44px;
    height: 44px;
    z-index: 1000;
  }
  &__content {
    padding: 32px 24px;
    position: relative;

    @media (min-width: 768px) {
      padding: 48px 32px;
    }
  }
  &__title {
    @include text(32px, 800, 40px, $black);
    text-align: left;
    margin-bottom: 5px;
    span {
      color: $primary;
    }
    &--offer {
      margin-bottom: 24px;
    }
  }
  &__desc {
    @include text(18px, 400, 26px, $black);
    text-align: left;
    margin-bottom: 16px;
    &--offer {
      p {
        @include text(18px, 400, 26px, $black);
      }

      h3,
      h4 {
        @include text(24px, 400, 32px);
      }
      strong {
        font-weight: 800;
      }
    }
  }

  &__action {
    margin-top: 24px;
    margin-left: 20px;
    .btn {
      &:before {
        width: 100%;
      }
    }
  }
  &__form {
    position: relative;
    text-align: left;
  }
  &__image {
    height: 100%;
    width: 100%;
    display: flex;
    picture {
      display: flex;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    margin-top: 16px;
  }
  &__socials {
    display: flex;
    gap: 24px;
    white-space: nowrap;
  }
}

.offer-popup {
  &.isActive {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.6);
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
