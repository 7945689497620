.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &__header {
    @media (min-width: 577px) {
      display: none;
    }
  }
  @media (min-width: 1101px) {
    &__bottom,
    .social-media {
      display: none;
    }
  }
  &__logo {
    max-width: 72px;

    svg {
      width: 100%;
    }
  }
  .btn--sm {
    white-space: nowrap;
    @media (max-width: 1100px) {
      margin-right: 30px;
    }
    @media (min-width: 1101px) {
      margin-left: 30px;
    }
  }
  ul.menu {
    flex-direction: row;
    display: flex;
    gap: 0px 48px;
    @media (min-width: 1101px) and (max-width: 1440px) {
      gap: 0px 24px;
    }
    @media (min-width: 1441px) {
      gap: 0px 48px;
    }

    > li {
      display: flex;
      align-items: center;
      position: relative;
      &.menu-item-has-children {
        cursor: pointer;
        > a {
          pointer-events: none;
        }

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          left: -16px;
          top: 18px;
          background-image: url(../img/icons/triangle.svg);
          background-repeat: no-repeat;
          transition: $transition-basic;
        }
      }
      &:hover {
        @media (min-width: 1101px) {
          &.menu-item-has-children {
            &:before {
              transform: rotate(90deg);
            }
            .sub-menu {
              display: flex;
              opacity: 1;
            }
          }
        }
      }
      &.menu-button {
        > a {
          @extend .btn;
          border: 2px solid transparent;
          @include text(12px, 700, 15px, $white);
          height: 50px;
          min-width: auto;

          &:hover {
            background: transparent;
            border: 2px solid $white;
            color: $white;
          }
        }
      }
      &:not(.menu-button) {
        @media (min-width: 1101px) {
          > a {
            position: relative;
            padding: 8px 0px;
            @include text(14px, 700, 21px, $white);
            font-family: $font-family-basic;
            letter-spacing: 1px;
            text-transform: uppercase;
            @media (min-width: 1441px) {
              @include text(16px, 700, 26px);
            }
          }
        }
      }
      @media (min-width: 1101px) {
        &:not(.menu-button):not(.menu-item-has-children):not(
            .current-menu-item
          ) {
          &:after {
            content: "";
            position: absolute;
            bottom: 8px;
            left: 0;
            background: $primary;
            height: 2px;
            width: 0;
            transition: $transition-basic;
            transform: translateX(-5px);
          }
          &:hover {
            &:after {
              width: 100%;
              transform: translateX(0px);
            }
          }
        }
      }
    }
  }
  ul.sub-menu {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    @media (max-width: 1100px) {
      display: none;
      &.active {
        display: flex;
      }
    }
    @media (min-width: 1101px) {
      position: absolute;
      top: calc(100% - 5px);
      border-radius: 0px 21px 0px 21px;
      padding: 14px 24px;
      width: 100%;
      min-width: 200px;
      opacity: 0;
      left: -16px;
      transition: $transition-basic;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.18);
        backdrop-filter: blur(12px);
        filter: brightness(1.7);
        border-radius: 0px 21px 0px 21px;
      }
    }

    > li {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 6px;
      }

      a {
        @include text(14px, 300, 24px, $white);
        padding: 10px 0px;
        transition: $transition-basic;
        &:hover {
          color: $primary;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    position: fixed;
    top: 0px;
    right: 0;
    transform: translateX(100%);
    background: $primary;
    padding: 24px 36px 64px;
    height: 100vh;
    width: 100%;
    transition: $transition-basic;
    z-index: 99;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: auto;
    > div {
      width: 100%;
    }
    @media (min-width: 577px) {
      padding: 100px 24px 32px;
      width: 320px;
      top: 61px;
      height: calc(100vh - 61px);
    }
    .social-media {
      justify-content: center;
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    ul.menu {
      overflow-x: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding-left: 20px;
      padding-bottom: 24px;

      > li {
        width: 100%;
        margin-bottom: 4px;
        flex-direction: column;
        align-items: flex-start;
        &.menu-item-has-children {
          &:before {
            top: 14px;
            transform: none;
          }
        }
        &.menu-button {
          margin-bottom: 24px;
        }

        @media (max-width: 576px) {
          order: 2;

          &.menu-button {
            margin-left: -20px;
            order: 1;
            a {
              height: 84px;
              width: 100%;
              max-width: 320px;
              font-size: 20px;
              white-space: nowrap;
              border-radius: 0px 32px 0px 28px;
            }
          }
        }
        > a {
          display: block;
          @include text(20px, 700, 25px, $white);
          font-family: $font-family-basic;
          text-transform: uppercase;
          padding: 8px 0px;
        }
      }
    }

    &.active {
      transform: translateX(0);
      @media (max-width: 576px) {
        + .header__right {
          .cart {
            transform: translateY(-39px);
          }
        }
      }
    }
  }
}

#toggleMenu {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  transition: $transition-basic;
  z-index: 100;

  span {
    display: block;
    position: absolute;
    height: 2.95px;
    width: 40px;
    background: $white;
    border-radius: 8px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 13px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 26px;
      transform-origin: left center;
    }
  }

  @media (min-width: 1101px) {
    display: none;
  }

  &.opened {
    @media (max-width: 576px) {
      transform: translateY(-39px);
    }
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 3px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 28px;
        left: 3px;
      }
    }
  }
}
