.social-media {
  display: flex;
  align-items: center;
  gap: 16px;
  li {
    a {
      display: flex;
      align-items: center;
    }
  }
}
