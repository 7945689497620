.circle {
  &-dots {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    gap: 16px;
    margin-top: 170px;

    &--about {
      margin: 80px 0;
    }
    &__single {
      width: calc(25% - 16px);
    }
    &__title {
      @include text(22px, 800, 30px, $primaryGrey);
      letter-spacing: -0.6px;
      font-style: italic;
      margin-bottom: 8px;
      text-align: center;
      text-transform: uppercase;

      &-solo {
        @include text(16px, 800, 18px, $primaryGrey);
        margin: 20px 0 8px;
        span,
        b,
        strong {
          color: $primary;
        }
      }
    }
    &__desc {
      @include text(16px, 300, 20px, $primaryGrey);
      letter-spacing: -0.4px;
      font-style: italic;
      max-width: 200px;
      margin: 0 auto;
      text-align: center;
    }
    &__img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }

    &__decor {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      z-index: -1;
      width: 100%;
      max-width: 247px;
    }
    &__dot {
      width: 160px;
      height: 160px;
      background: $primary;
      border-radius: 100%;
      box-shadow: 0px 4px 6px #00000029;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.premium {
      .circle-dots__dot {
        background: $primaryRed;
      }
    }
  }
}

@media (max-width: 991px) {
  .circle-dots {
    gap: 30px;
    &__single {
      width: calc(50% - 16px);
    }
  }

  .page-template-offer-acrobatics {
    .circle-dots {
      margin-top: 60px;
    }
  }
  .page-template-offer-flynight {
    .circle-dots {
      margin-top: 0;
    }
  }
}

@media (max-width: 550px) {
  .page-template-offer-acrobatics {
    .circle-dots {
      gap: 50px;
    }
  }
  .circle-dots {
    &__single {
      width: 100%;
    }
  }
}
