@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
}
@mixin text($size, $font-weight, $line-height, $color: false) {
  font-size: $size;
  font-weight: $font-weight;
  line-height: $line-height;
  @if $color {
    color: $color;
  }
}