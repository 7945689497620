.about {
  &__title {
    @include text(48px, 800, 52px, $primaryGrey);
    text-align: center;
    span {
      color: $primary;
    }
  }
}

@media (max-width: 757px) {
  .about {
    &__title {
      font-size: 32px;
      line-height: 34px;
      text-align: left;
    }
  }
}
