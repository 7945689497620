.popup-widget {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: $primary;
  align-items: center;
  cursor: pointer;
  position: fixed;
  left: 0px;
  bottom: 48px;
  z-index: 997;
  transition: $transition-basic;
  display: none;
  @media (min-width: 1024px) {
    justify-content: flex-end;
    width: 60px;
    left: -20px;
    bottom: auto;
    top: 50%;
    padding-right: 8px;
    &:hover {
      left: 0;
    }
  }
  &.isVisible {
    display: flex;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
