.article {
  padding: 60px 0px;
  overflow-x: hidden;
  &__header {
    margin-bottom: 24px;
  }
  &__date {
    @include text(12px, 700, 19px, $primaryGrey);
  }
  &__title {
    @include text(40px, 900, 64px, $primaryGrey);
    letter-spacing: -1.25px;
  }
  &__content {
    h2,
    h3,
    h4,
    h5,
    h6,
    ul li,
    ol li {
      text-align: left;
    }
    margin-bottom: 48px;
    h2 {
      @include text(32px, 500, 42px, $primaryGrey);
      @media (min-width: 992px) {
        @include text(40px, 500, 54px);
      }
    }
    h3 {
      @include text(24px, 500, 32px, $primaryGrey);
      @media (min-width: 992px) {
        @include text(32px, 500, 32px);
      }
    }
    h3 + p,
    h3 + ul,
    h3 + ol {
      margin-top: 20px;
    }
    h2 + p,
    h2 + ol,
    h2 + ul {
      margin-top: 20px;
    }
    p {
      @include text(14px, 400, 24px, $primaryGrey);
      @media (min-width: 992px) {
        @include text(16px, 400, 28px, $primaryGrey);
      }
      + h2 {
        margin-top: 32px;
      }
      + h3 {
        margin-top: 32px;
      }
      + ul,
      ol {
        margin-top: 24px;
      }
      + p {
        margin-top: 16px;
        @media (min-width: 992px) {
          margin-top: 24px;
        }
      }
    }
    ul {
      padding-left: 16px;
      list-style-type: disc;
    }
    ol {
      padding-left: 16px;
    }
    ul li,
    ol li {
      @include text(14px, 400, 24px, $primaryGrey);
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      @media (min-width: 992px) {
        @include text(16px, 400, 28px, $primaryGrey);
      }
    }
    figure {
      &.wp-block-image {
        margin: 48px 0px;
        img {
          height: auto;
          border-radius: 16px;
        }
      }
      &.wp-block-embed {
        display: flex;
        justify-content: center;
        margin: 48px 0px;
        iframe {
          max-width: 100%;
        }
      }
      &.wp-block-embed-spotify {
        .wp-block-embed__wrapper {
          width: 100%;
        }
      }
      &.wp-block-video {
        display: flex;
        justify-content: center;
        margin: 48px 0px;
        video {
          max-width: 100%;
          border-radius: 16px;
          max-height: 90vh;
        }
      }
      &.wp-block-pullquote {
        padding: 0;
        margin: 48px 0px;
        blockquote {
          display: flex;
          flex-direction: column-reverse;
          text-align: left;
          border-left: 2px solid $black;
          padding-left: 20px;
          margin: 0;
          cite {
            @include text(20px, 500, 36px, $black);
          }
          p {
            @include text(15px, 400, 24px, $primaryGrey);
            margin-top: 32px;
          }
        }
      }
    }
  }
}
