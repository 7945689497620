.price {
  display: flex;
  align-items: flex-start;
  &-qty {
    @include text(68px, 800, 72px);
    font-style: italic;
    letter-spacing: -3.4px;
  }
  &-info {
    margin-left: 10px;
  }
  &-currency {
    @include text(32px, 800, 35px);
    font-style: italic;
  }
  &-additional-info {
    @include text(16px, 400, 18px);
    font-style: italic;
  }

  &--xs {
    .price {
      &-info {
        margin: 6px 0px 0px 10px;
      }
      &-qty {
        font-size: 44px;
        line-height: 48px;
      }
      &-currency {
        font-size: 20px;
        line-height: 24px;
      }
      &-additional {
        &-info {
          font-size: 12px;
          line-height: 10px;
        }
      }
    }
  }
}
