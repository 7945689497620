.btn {
  outline: none;
  box-shadow: none;
  position: relative;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 260px;
  transition: 0.3s ease-in-out;
  background: transparent !important;
  border: none;
  cursor: pointer;

  &:before {
    content: "";
    height: 58px;
    width: 130%;
    position: absolute;
    background: $primary;
    left: 50%;
    transform: translateX(-50%) skewX(-30deg);
    z-index: 1;
    border: 1px solid $primary;
    transition: 0.3s ease-in-out;
    min-width: 210px;
  }

  span {
    position: relative;
    z-index: 2;
    @include text(16px, 800, 25px, $white);
    font-style: italic;
    transition: 0.3s ease-in-out;
    text-transform: uppercase;
  }

  &:hover {
    &:before {
      background: transparent;
      border-color: $primaryGrey;
    }
    span {
      color: $primaryGrey;

      span {
        color: $primary !important;
      }
    }
  }

  &.bg--dark {
    &:hover {
      &:before {
        border-color: $primary;
      }
      span {
        color: $white;
      }
    }
  }
  &:not(.btn--outlined) {
    &.premium {
      &:before {
        background: $primaryRed;
        border-color: $primaryRed;
      }
      &:hover {
        &:before {
          background: transparent;
          border-color: $primaryRed;
        }
        span {
          span {
            color: $primaryRed !important;
          }
        }
      }
    }
  }

  &--outlined {
    max-width: 170px;

    &.vip {
      &.active {
        span {
          color: $white;
        }
        &:before {
          background: $primaryGrey;
          border-color: transparent;
        }
      }
    }
    span {
      color: $primaryGrey;
    }
    &:before {
      background: transparent;
      border-color: $primaryGrey;
    }
    &:hover {
      &:before {
        background: $primaryGrey;
      }
      span {
        color: $white !important;
      }
    }
    &.premium {
      span {
        color: $primaryRed;
      }
      &:before {
        border-color: $primaryRed;
      }
      &:hover {
        &:before {
          background: $primaryRed;
        }
        span {
          color: $white !important;
        }
      }
      &.active {
        span {
          color: $white;
        }
        &:before {
          background: $primaryRed;
          border-color: transparent;
        }
      }
    }
    &.standard {
      span {
        color: $primary;
      }
      &:before {
        border-color: $primary;
      }
      &:hover {
        &:before {
          background: $primary;
        }
        span {
          color: $white !important;
        }
      }
      &.active {
        span {
          color: $white;
        }
        &:before {
          background: $primary;
          border-color: transparent;
        }
      }
    }
    &.custom {
      &:hover {
        &:before {
          border-image: linear-gradient(180deg, #e2404e 0%, #e9ac24 100%) 1;
          background: transparent
            linear-gradient(180deg, #e2404e 0%, #e9ac24 100%) 0% 0% no-repeat
            padding-box;
        }
      }
      &.active {
        span {
          color: $white !important;
        }
        &:before {
          background: transparent
            linear-gradient(180deg, #e2404e 0%, #e9ac24 100%) 0% 0% no-repeat
            padding-box;
          border-image: linear-gradient(180deg, #e2404e 0%, #e9ac24 100%) 1;
        }
      }
    }
    &.btn--banner-cta {
      span {
        color: $white;
        span {
          color: $primary;
        }
      }
      &:before {
        border-color: $white;
      }

      &:hover {
        span {
          color: $white;

          span {
            color: $white !important;
          }
        }
        &::before {
          border-color: transparent;
          background: $primary;
        }
      }
      &-mobile {
        margin: 0;
      }
      &.premium {
        span {
          span {
            color: $primaryRed !important;
          }
        }
        &:hover {
          &:before {
            background: $primaryRed;
          }
          span {
            span {
              color: $white !important;
            }
          }
        }
      }
    }
  }
  &--pricing {
    height: 40px;
    width: 25%;
    background: $white;
    span {
      @include text(12px, 800, 14px);
      color: rgba(#5c5c5b, 0.5);
    }
    &:before {
      height: 40px;
      box-shadow: 0px -3px 6px #00000029;
      border-color: rgba(#5c5c5b, 0.5);
      background: $white;
    }

    &.standard,
    &.premium {
      span {
        color: rgba(#5c5c5b, 0.5);
      }
      &:before {
        box-shadow: 0px -3px 6px #00000029;
        border-color: rgba(#5c5c5b, 0.5);
      }
      &:hover {
        &:before {
          border-color: transparent;
        }
      }
    }
  }

  &--banner {
    &:before {
      min-width: 180px;
    }
    &-cta {
      margin: 30px 0 0 38px;
      &:before {
        width: unset;
      }
    }
  }
}

@media (max-width: 991px) {
  .btn {
    &--pricing {
      height: 66px;
      max-width: 220px;
      span {
        font-size: 19px;
        line-height: 21px;
      }
      &:before {
        height: 66px;
      }
    }

    &--banner {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .btn {
    &--banner {
      &-cta {
        margin-left: 70px;
        height: 70px;

        span {
          font-size: 20px;
          line-height: 22px;
          white-space: nowrap;
        }

        &:before {
          min-width: 270px;
          height: 70px;
        }

        &-mobile {
          margin: 0;
          transform: none;
          height: 58px;

          span {
            font-size: 16px;
            line-height: 18px;
          }
          &:before {
            height: 58px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .btn {
    &--banner {
      &-cta {
        margin-left: 50px;
        &:before {
          min-width: 230px;
        }
      }
    }
  }
}
