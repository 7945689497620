.banner {
  &-single {
    &__block {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &__logo {
      position: relative;
      z-index: 100;
    }
    &__wrapper {
      &--lg {
        .banner-single {
          min-height: 830px;
        }
      }
      &--xl {
        .banner-single {
          min-height: 960px;
        }
      }
    }
    background: gray;
    position: relative;
    top: -58px;
    padding-top: 84px;
    min-height: 730px;

    &__localization {
      @include text(12px, 300, 14px, $white);
      margin-right: 150px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 30px 0 30px 30px;
      cursor: pointer;
      &.always-active {
        @media (max-width: 767px) {
          display: none;
        }
        .banner {
          &-single {
            &__localization {
              &-more {
                width: inherit;
                opacity: 1;
              }
            }
          }
        }
      }
      &:hover {
        .banner {
          &-single {
            &__localization {
              &-more {
                width: inherit;
                opacity: 1;
              }
            }
          }
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }
      &-icon {
        margin: 0 6px;

        &--small {
          width: 10px;
        }
      }
      &-chosen {
        @include text(16px, 800, 18px, $white);
        font-style: italic;
      }
      &-more {
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 0;
        opacity: 0;

        ul {
          display: flex;
          padding: 10px 20px;
          gap: 30px;
          border-top: 1px solid rgba($white, 0.5);

          li {
            a {
              display: flex;
              align-items: center;
              @include text(12px, 300, 14px, $white);
              margin: 0;
              transition: font-weight 0.3s ease;

              &:hover {
                // font-weight: bold;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__title {
      @include text(74px, 800, 64px, $white);
      letter-spacing: -1.9px;
      text-transform: uppercase;
      margin-bottom: 20px;
      font-style: italic;

      span {
        color: $primary;
      }
      &--premium {
        span {
          color: $primaryRed;
        }
      }
    }

    &__desc {
      @include text(20px, 200, 24px, $white);
      max-width: 520px;
      font-style: italic;

      a {
        color: $white;

        &:hover {
          color: $primary;
        }
      }
    }
    &__bg,
    #player {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: relative;
      @media (min-width: 992px) {
        padding: 160px 0px 50px;
      }

      &-info {
        margin-bottom: 160px;
        // max-width: 510px;
        max-width: 660px;
      }
    }

    &__social {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 20px;
      right: -80px;
      top: 170px;

      &-single {
        border: 1px solid #fff;
        border-radius: 100%;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s ease-in-out;
        img {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background: $primary;
          border-color: $primary;
        }
      }
    }

    &__img {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      display: flex;
      justify-content: flex-end;
      picture {
        display: flex;
        justify-content: flex-end;
      }
      img {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1520px) {
  .banner-single {
    &__social {
      right: 16px;
    }
    &__info {
      .btn {
        margin-right: 70px;
      }
    }
  }
}

@media (max-width: 991px) {
  .banner-single {
    min-height: 530px !important;
    &__content {
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;
      padding-right: 40px;
      &-info {
        order: 1;
        max-width: 100%;
        position: relative;
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &__title {
      font-size: 40px;
      letter-spacing: -1px;
      line-height: 35px;
      margin-bottom: 10px;
    }
    &__img {
      position: relative;
      justify-content: center;
      margin-bottom: 20px;
      margin-left: 50%;
      transform: translateX(-50%);
      picture {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }
      img {
        width: 100%;
        max-width: 320px;
        min-width: 320px;
      }
    }
    &__social {
      display: none;

      &--footer {
        display: flex;
        flex-direction: row;
        position: unset;
        gap: 30px;
      }
    }
    &__container {
      .container {
        padding: 0;
      }
    }
    &__localization {
      margin: 0;
      margin-right: 20px;
    }
  }
}

@media (max-width: 767px) {
  .banner-single {
    &__desc {
      font-size: 16px;
      line-height: 20px;
    }
    &__logo {
      min-width: 140px;
      width: 140px;
    }
    &__info {
      position: relative;
    }
    &__localization {
      position: absolute;
      bottom: -90px;
      right: -50px;
      padding: 0;
      transform: rotate(-90deg);
      z-index: 99;
      > span {
        display: none;
      }

      &-more {
        display: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .banner-single {
    &__img {
      img {
        min-width: 280px;
        max-width: 280px;
      }
    }
  }
}
