.custom-blog-row-section {
  display: flex;
  align-items: center;
  gap: 32px;
  margin: 80px 0px;
  margin-left: -166px;
  width: calc(100% + 132px);
  @media (max-width: 767px) {
    margin: 48px 0 24px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }
  &__image {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 540px;
    @media (max-width: 991px) {
      justify-content: center;
    }
  }
  &__decor {
    position: absolute;
    top: -30px;
    left: 30px;
    width: 200px;
    height: 200px;
    @media (max-width: 576px) {
      display: none;
    }
  }
  &__content {
    @media (min-width: 992px) {
      width: calc(100% - 540px);
    }
  }
  &__title {
  }
  &__desc {
  }
}
