.birthday {
  &-tier {
    width: calc(33% - 16px);
    &__title {
      text-align: center;
      font-style: italic;
      @include text(24px, 300, 26px, $white);
      letter-spacing: -0.6px;
      strong {
        font-weight: bold;
      }
    }
    &__wrapper {
      display: flex;
      margin-top: 34px;
    }

    &__type {
      width: 205px !important;
      background: $primaryGrey;
      text-align: center;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: skewX(-30deg);
      position: relative;
      margin-left: 35px;
      h3 {
        transform: skewX(30deg);
        @include text(20px, 800, 22px, $white);
        font-style: italic;
      }

      &:before,
      &:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 54px;
        transform: skewX(-2deg);
        display: block;
        background-color: #5c5c5a;
        top: 0;
        bottom: 0;
        right: -20px;
      }

      &:after {
        right: -40px;
      }
      &:first-child {
        .birthday-tier__type {
          margin-left: 0;
        }
      }

      &--decorless {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    &--premium {
      .birthday {
        &-tier {
          &__type {
            background: $primaryRed;

            &:before,
            &:after {
              background-color: $primaryRed;
            }
          }
        }
      }
      li {
        &:before {
          background-color: $primaryRed;
        }
      }
    }

    &--standard {
      .birthday {
        &-tier {
          &__type {
            background: $primary;

            &:before,
            &:after {
              background-color: $primary;
            }
          }
        }
      }
      li {
        &:before {
          background-color: $primary;
        }
      }
    }
  }
  &-decor {
    position: relative;
    height: 250px;
    margin-top: 240px;
    background: $primaryRed;
    @media (min-width: 1921px) {
      height: 360px;
      margin-top: 350px;
    }

    img {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      right: 0;
      max-height: 460px;
      object-fit: cover;
      object-position: top;
      @media (min-width: 1921px) {
        max-height: 550px;
      }
    }
  }
  &-pricing {
    display: flex;
    margin: 50px 0 30px;
    &__single {
      display: flex;
      width: calc(33% - 16px);
      flex-direction: column;
      &-wrapper {
        display: flex;
        margin-bottom: 20px;
      }
    }
    &__from {
      font-style: italic;
      @include text(24px, 800, 26px);
    }
    &__price {
      font-style: italic;
      letter-spacing: -5px;
      @include text(100px, 800, 104px);
    }
    &__currency {
      font-style: italic;
      @include text(24px, 800, 26px);
      display: flex;
      align-items: flex-end;
    }
    &__btn {
      margin-left: 42px;
    }
  }
  &-table {
    margin-bottom: 30px;

    &__info {
      margin: 50px 0 30px;
      display: flex;
      align-items: center;
      div {
        width: 20%;
        &:first-child {
          width: 40%;
        }
        &:nth-child(2) {
          .birthday-tier__type {
            background: $primaryRed;
          }
        }
        &:nth-child(3) {
          .birthday-tier__type {
            background: $primary;
          }
        }
      }
      &-title {
        font-style: italic;
        @include text(16px, 800, 18px);
        letter-spacing: -0.4px;
        padding-left: 12px;
      }
    }

    &__additional {
      font-style: italic;
      letter-spacing: -0.5px;
      @include text(20px, 800, 24px);
      padding-left: 12px;
      margin-bottom: 30px;
    }
    &__row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(#707070, 0.3);
      height: 64px;
      padding-left: 12px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  &-row {
    width: 20%;
    @include text(16px, 300, 18px);
    letter-spacing: -0.4px;
    text-align: center;
    font-style: italic;
    color: $primaryGrey;

    &--premium {
      color: $primaryRed;
    }

    &--standard {
      color: $primary;
    }

    &--vip {
      color: $primaryGrey;
    }

    span {
      color: $primaryGrey;
    }
    &:first-child {
      width: 40%;
      text-align: left;
    }

    &--bold {
      @include text(24px, 800, 26px);
      font-style: italic;
    }

    &--double {
      display: flex;
      flex-direction: column;

      span {
        @include text(12px, 300, 14px);
      }
    }
  }
  &-scenario {
    &__title {
      font-style: italic;
      @include text(36px, 800, 40px);
      letter-spacing: -0.9px;
      text-align: center;
      span {
        color: $primaryRed;
      }
    }
    &-info {
      width: calc(33% - 16px);
      padding-left: 30px;
      &__wrapper {
        display: flex;
        margin-top: 55px;
      }
      &__title {
        font-style: italic;
        @include text(40px, 800, 44px);
        letter-spacing: -1px;
      }

      &--premium {
        span {
          color: $primaryRed;
        }

        li {
          &:before {
            background: $primaryRed;
          }
        }
      }
      &--standard {
        span {
          color: $primary;
        }

        li {
          &:before {
            background: $primary;
          }
        }
      }
    }
    &__list {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 32px;

      &-item {
        font-style: italic;
        @include text(16px, 300, 18px);
        display: flex;
        max-width: 300px;
        &:before {
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 100%;
          margin-right: 10px;
          background: $primaryGrey;
          display: block;
          min-width: 14px;
          min-height: 14px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .birthday {
    &-tier {
      margin-top: 40px;
      border: 1px solid rgba($primaryGrey, 0.5);
      padding: 50px 20px 30px;
      width: 50%;
      position: relative;

      &__wrapper {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        .birthday-tier__type {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: skewX(-30deg) translateX(-75%);
        }
      }

      .birthday-pricing__single {
        margin-top: 20px;
        width: 100%;
      }

      &__title {
        color: $black;
      }
    }
    &-scenario {
      &__list {
        margin-top: 0;
        gap: 16px;

        &-item {
          font-size: 20px;
          line-height: 22px;
          max-width: 100%;
        }
      }
      &-info {
        padding-left: 0;
        &__wrapper {
          margin-top: 0;
        }
      }
    }
    &-pricing {
      display: none;
    }
    &-row {
      width: 33%;
      &:first-child {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &-table {
      margin-bottom: 10px;
      &__additional {
        padding-left: 0;
        margin-bottom: 10px;
      }
      &__row {
        flex-wrap: wrap;
        height: unset;
        padding: 16px 0;
      }
      &__info {
        flex-direction: column;
        gap: 10px;

        div {
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0;
        }
      }
    }
  }

  .section--bday {
    .birthday-scenario-info__wrapper {
      margin-top: 30px;
    }
    .birthday-scenario__list {
      margin-top: 30px;
    }
  }
}

@media (max-width: 767px) {
  .birthday {
    &-tier {
      width: 100%;
    }
    &-scenario {
      &-info {
        width: 100%;
        &__wrapper {
          flex-direction: column;

          .birthday-scenario__list {
            margin: 20px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .birthday {
    &-row {
      &--bold {
        font-size: 22px;
        line-height: 24px;
      }
    }
    &-decor {
      height: 150px;
    }
  }
}

@media (max-width: 400px) {
  .birthday {
    &-decor {
      height: 100px;
    }
  }
}
