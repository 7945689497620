.offer {
  &-single {
    position: relative;
    margin-bottom: 100px;
    &:last-child {
      margin-bottom: 60px;
    }
    &:before {
      content: "";
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(245, 245, 245, 1) 100%
      );
      position: absolute;
      left: 0;
      top: 0;
      right: 130px;
      bottom: 0;
      transform: skew(-20deg);
    }

    &__date {
      @include text(12px, 800, 14px);
      margin-bottom: 6px;
    }

    &__img {
      width: 33%;
    }

    &__info {
      width: 66%;
    }
    &__wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    &__title {
      font-size: 48px;
      line-height: 42px;
      font-weight: 800;
      letter-spacing: -1.2px;
      font-style: italic;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      text-transform: uppercase;
      span {
        color: $primary;
      }
    }
    &__whom {
      @include text(20px, 300, 26px, $primaryGrey);
      letter-spacing: -0.5px;
      margin: 10px 0;
      font-style: italic;
    }
    &__desc {
      p {
        letter-spacing: -0.4px;
        @include text(16px, 300, 20px, $primaryGrey);
        font-style: italic;
        max-width: 675px;
      }
    }
    &__additional {
      margin-top: 20px;

      ul {
        display: flex;
        gap: 20px;
        li {
          @include text(12px, 700, 18px, $primaryGrey);
          list-style-type: circle;
          display: flex;
          align-items: center;
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            display: block;
            background: $primary;
            border-radius: 100%;
            margin-right: 18px;
          }
        }
      }
    }
    &.premium {
      .offer-single {
        &__btn {
          a {
            background: $primaryRed;
          }
        }
        &__title {
          span {
            color: $primaryRed;
          }
        }
        &__additional {
          ul {
            li {
              &:before {
                background: $primaryRed;
              }
            }
          }
        }
      }
    }
    &.vip {
      .offer-single {
        &__btn {
          a {
            background: $primaryGrey;
          }
        }
        &__title {
          span {
            color: $primaryGrey;
          }
        }
        &__additional {
          ul {
            li {
              &:before {
                background: $primaryGrey;
              }
            }
          }
        }
      }
    }
    &__btn {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: -60px;
      bottom: -15px;
    }
    &--blog {
      padding: 70px 0;

      .offer {
        &-single {
          &__btn {
            bottom: -95px;
          }
          &__title {
            font-size: 32px;
            line-height: 34px;
          }
        }
      }
    }
  }
  &-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      @include text(40px, 800, 50px, $white);
      font-style: italic;
      span {
        color: $primary;
      }
    }
    &-section {
      background: $primaryGrey;
      padding: 16px 0;
      margin-bottom: 30px;
      &--premium {
        .offer-cta {
          &__title {
            span {
              color: $primaryRed;
            }
          }
        }
      }
    }
  }
  &-morning {
    .offer-single {
      &__title {
        margin-bottom: 20px;
      }
      &:nth-of-type(2n) {
        .offer-single {
          &__img {
            order: 2;
          }
        }
        &:before {
          right: 0;
          left: 12%;
          background: linear-gradient(
            90deg,
            rgba(245, 245, 245, 1) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
      &:before {
        right: 25%;
      }
    }
  }
  &-acrobatics {
    .offer-single {
      &__title {
        margin-bottom: 20px;
        span {
          color: $primaryRed;
        }
      }
    }
  }

  &.premium {
    .offer {
      &-single {
        &__title {
          margin-bottom: 20px;
          span {
            color: $primaryRed;
          }
        }
      }
    }
  }
}

.acrobatics {
  &-dots {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    gap: 16px;
    &__single {
      width: calc(25% - 16px);
    }
    &__title {
      @include text(22px, 800, 30px, $primaryGrey);
      letter-spacing: -0.6px;
      font-style: italic;
      white-space: nowrap;
      margin-bottom: 8px;
    }
    &__desc {
      @include text(16px, 300, 20px, $primaryGrey);
      letter-spacing: -0.4px;
      font-style: italic;
      max-width: 200px;
      text-align: center;
    }
    &__img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__decor {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      top: -50%;
      transform: translateY(25%);
      z-index: -1;
    }
    &__dot {
      width: 160px;
      height: 160px;
      background: $primaryRed;
      border-radius: 100%;
      box-shadow: 0px 4px 6px #00000029;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

.offer {
  &-pricing {
    display: flex;
    padding: 50px 0 50px 150px;
    margin-bottom: 80px;

    &__dots-wrapper {
      display: none;
    }

    &__bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent linear-gradient(270deg, #ffffff 0%, #f5f5f5 100%)
        0% 0% no-repeat padding-box;
      z-index: -1;
      transform: skewX(-20deg);
    }
    &--left {
      width: 75%;
    }
    &--right {
      position: relative;
      width: 25%;
      top: -100px;
    }

    &__single {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__dot {
      width: 260px;
      height: 260px;
      background: $primaryRed;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      @include text(48px, 800, 54px, $white);
      font-style: italic;
      letter-spacing: -1.2px;
      text-align: center;
      border-radius: 100%;
      margin-bottom: 12px;
    }
    &__decor {
      position: absolute;
      left: -10px;
      top: -50px;
      width: 350px;
      height: 350px;
      z-index: -1;
      transform: scale(1.2);
    }
    &__join {
      @include text(31px, 800, 34px, $primaryGrey);
      font-style: italic;
      letter-spacing: -0.78px;
      text-align: center;
      + a {
        margin-top: 24px;
        max-width: 180px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__title {
      @include text(20px, 300, 22px);
      font-style: italic;
      letter-spacing: -0.3px;
    }
    &__info {
      margin-top: 10px;
      p {
        @include text(16px, 300, 18px);
        font-style: italic;
        letter-spacing: -0.24px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      margin-top: 16px;
      &-wrapper {
        display: flex;
        gap: 36px;
      }
      &-icon {
        margin-right: 16px;
        width: 70px;
        height: 70px;
        background: $primaryRed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
      &-href {
        @include text(24px, 800, 26px, $primaryGrey);
        font-style: italic;
        letter-spacing: -0.6px;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    &--flynight {
      margin-bottom: 0;
      .offer-pricing {
        &__info {
          p {
            display: block;
            margin-bottom: 36px;
            strong {
              color: $primary;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &__link {
          &-icon {
            background: $primary;
          }
        }
        &__dot {
          background: $primaryGrey;
          flex-direction: column;

          span {
            &:first-child {
              color: $primary;
              font-size: 16px;
              line-height: 18px;
              letter-spacing: -0.4px;
            }
            &:nth-child(2) {
              font-size: 32px;
              line-height: 34px;
              letter-spacing: -0.8px;
            }
            &:nth-child(3) {
              color: $primary;
              font-size: 44px;
              line-height: 48px;
              letter-spacing: -0.66px;
            }
          }
        }
      }
    }
  }
}

.schedule {
  column-count: 2;
  position: relative;
  padding-top: 20px;
  margin-bottom: 20px;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background: rgba(#707070, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 45%;
    transform: translateX(-50%);
  }
  &__title {
    font-style: italic;
    letter-spacing: -1.4px;
    @include text(40px, 800, 44px);
    margin: 40px 0 60px;
    text-align: center;

    span {
      color: $primary;
    }
  }
  &__item {
    break-inside: avoid-column;
  }
  &__label {
    @include text(16px, 800, 18px);
    margin-bottom: 8px;
    letter-spacing: -0.24px;
  }
  &__list {
    padding-left: 16px;
    margin-bottom: 20px;
    display: inline-block;
    max-width: 440px;
    &-item {
      @include text(16px, 300, 18px);
      letter-spacing: -0.24px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1199px) {
  .offer {
    &-cta {
      &__title {
        font-size: 32px;
        line-height: 34px;
      }
      .btn {
        margin-right: 60px;
      }
    }
  }
}

@media (max-width: 991px) {
  .offer {
    &-cta {
      &__title {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &-morning {
      .offer {
        &-single {
          &:before {
            right: 0;
          }
          &:nth-of-type(2) {
            &:before {
              left: 0;
            }
          }
        }
      }
    }
    &-single {
      margin: 250px 0;

      &:first-child {
        margin-top: 150px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      &:only-child {
        margin-top: 150px;
        margin-bottom: 0;
      }
      &:before {
        transform: none;
        right: 0;
      }

      &__wrapper {
        flex-wrap: wrap;
        padding: 100px 0 50px;
      }

      &__img {
        width: 100%;
        max-width: 350px;
        position: absolute;
        top: -200px;
        max-width: 320px;
      }

      &__info {
        width: 100%;
      }
    }
    &-acrobatics {
      .offer {
        &-single {
          &__wrapper {
            padding-bottom: 20px;
          }
        }
      }
    }

    &-pricing {
      padding: 10px 0 20px;
      margin-bottom: 0;

      &--left {
        width: 100%;
      }
      &__bg {
        transform: none;
      }

      &__dots {
        &-wrapper {
          display: flex;
          padding: 50px 0 20px;
          margin-top: 20px;
          background: $white;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: -40px;
            top: 0;
            bottom: 0;
            background: $white;
            height: 100%;
            width: 40px;
          }
          &::after {
            content: "";
            position: absolute;
            right: -40px;
            top: 0;
            bottom: 0;
            background: $white;
            height: 100%;
            width: 40px;
          }
        }
      }

      &--right {
        display: none;
        &-mobile {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          top: unset;
        }
      }

      &__decor {
        width: 215px;
        height: 215px;
        position: absolute;
        top: -30px;
        z-index: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &__dot {
        width: 160px;
        height: 160px;
        font-size: 28px;
        line-height: 30px;
        position: relative;
      }

      &__join {
        font-size: 24px;
        line-height: 26px;

        + a {
          position: relative;
          left: unset;
          transform: none;
        }
      }

      &--flynight {
        .offer-pricing {
          &__dot {
            span {
              &:nth-child(2) {
                font-size: 28px;
                line-height: 30px;
              }

              &:nth-child(3) {
                font-size: 30px;
                line-height: 32px;
              }
            }
          }
        }
      }
    }
  }

  .schedule {
    &__item {
      max-width: 350px;
    }
  }
}

@media (max-width: 767px) {
  .offer {
    &-cta {
      justify-content: center;
      &__title {
        display: none;
      }
      .btn {
        margin-right: 0;
      }
    }
    &-single {
      &__btn {
        justify-content: center;
        bottom: -25px;
      }
      &__info {
        margin-bottom: 20px;
      }
      &__additional {
        ul {
          flex-direction: column;
          gap: 10px;
        }
      }

      &__title {
        font-size: 38px;
        line-height: 40px;
        margin-bottom: 10px;
      }

      &__img {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-pricing {
      &__link {
        margin-top: 0;
        &:first-child {
          margin-top: 24px;
        }
        &-wrapper {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }

  .schedule {
    column-count: 1;

    &__title {
      margin: 20px 0;
    }
    &:before {
      display: none;
    }
  }
}
